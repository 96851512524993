<template>
    <div class="container-fluid p-0">
        <feedback-head  @update-feedbacks="updateFeedbacks($event)" />
        <feedback-data :feedbacks="feedbacks"  @process-feedback="processFeedback($event)" />
    </div>
    

</template>
 
<script>

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import API from './api'
import FeedbackHead from './FeedbackHead.vue';
import FeedbackData from './FeedbackData.vue';

const toast = useToast()
export default {
    name: 'Feedback',
    components: {
        FeedbackHead,
        FeedbackData
    },
    data() {
        return {
            feedbacks: {},
        }
    },
    created() {
        this.updateFeedbacks();
    },
    methods: {
        updateFeedbacks(query = null) {
            this.feedbacks = {};
            if(!query) {
                API.getFeedbacks({}).then(response => {
                    this.feedbacks = response.data.list;
                }).catch(() => {
                    toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error fetching feedbacks',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                    })
                })
            }else{
                API.filterFeedbacks(query).then(response => {
                    console.log("This is the filter response: ", response);
                    this.feedbacks = response.data.list;
                }).catch(() => {
                    toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error filtering feedbacks',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                    })
                })
            }
        },
        processFeedback(feedbackId) {
            if(feedbackId) {
                this.feedbacks = {};
                API.processFeedback(feedbackId).then(response => {
                    this.updateFeedbacks();
                }).catch(() => {
                    toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error processing feedbacks',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                    })
                })
            }
        }
    }
}
</script>

<style>

</style>