/* eslint-disable vue/singleline-html-element-content-newline */
<template>
   <div>
        <div class="all-feedbacks table-responsive p-0" id="f-holder">
            <div v-if="feedbacks && !feedbacks.length" class="text-center p-4">
                <h3>No results found</h3>
            </div>
            <div  v-else-if="!feedbacks" class="text-center p-4">
                <div class="spinner-border text-primary" role="status" style="width: 4rem; height: 4rem;">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <table v-else class="table rounded">
                <thead class="rounded">
                    <tr class="rounded">
                        <th>User Name</th>
                        <th>Type</th>
                        <th>Images</th>
                        <th>Content</th>
                        <th>Status</th>
                        <th>Created</th>
                        <th>Updated</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(feedback, i) in feedbacks" :item="feedback" v-bind="feedback" :index="i" :key="i" :class="{'alert-warning': !feedback.status}">
                        <td class="wrap">
                            {{feedback.userName}}
                        </td>
                        <td>{{(feedback.feedbackType == 1)? 'Hard to Use': (feedback.feedbackType == 2? 'Crash': (feedback.feedbackType == 3? 'Bug': (feedback.feedbackType == 4? 'Transaction': 'Othe')))}}</td>
                        <td>
                            <div class="f-images" v-for="(image, j) in feedback.images" :key="j">
                                <img :src="image" alt="">
                            </div>
                        </td>
                        <td>
                            <div class="f-content wrap" @click="showModal(feedback.content)">
                                {{feedback.content}}
                            </div>
                        </td>
                        <td>{{feedback.status? "Processed": "Pending"}}</td>
                        <td>{{new Date(feedback.createdAt).getFullYear()}}/{{new Date(feedback.createdAt).getMonth()}}/{{new Date(feedback.createdAt).getDay()}}</td>
                        <td>{{new Date(feedback.updatedAt).getFullYear()}}/{{new Date(feedback.updatedAt).getMonth()}}/{{new Date(feedback.updatedAt).getDay()}}</td>
                        <td>
                             <b-dropdown
                                variant="link"
                                no-caret
                                :right="$store.state.appConfig.isRTL"
                            >
                                <template #button-content>
                                <feather-icon
                                    icon="MoreVerticalIcon"
                                    size="16"
                                    class="align-middle text-body"
                                />
                                </template>
                                <b-dropdown-item v-if="!feedback.status">
                                <feather-icon icon="CheckIcon" />
                                <!-- data.item.id -->
                                <span class="align-middle ml-50"   @click="changeStatus(feedback._id)">Mark as Processed</span>
                                </b-dropdown-item>
                                <b-dropdown-item v-else :disabled="true">
                                <feather-icon icon="EyeIcon" />
                                <!-- data.item.id -->
                                <span class="align-middle ml-50">Processed</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
   </div>
</template>

<script>

const axios = require('axios');

export default {
    name: "FeedbackData",
    props: {
        feedbacks: {
            type: Object,
            default() {
                return {}
            }
        },
        status: {
            type: Number,
            default() {
                return 0;
            }
        }
    },
    data() {
        return{
            checked: ''
        }
    },
    watch: {
        // whenever question changes, this function will run
        feedbacks: function (newFeedbacks, old) {
            this.feedbacks = newFeedbacks;
        },

        status: function (newStatus, old) {
            console.log(this.status);
            this.status = newStatus;
            // if(this.status) {
            //     console.log('Unable to change feedback state');
            // }
        } 
    },
    methods: {
        changeStatus(feedbackId) {
            this.$emit('process-feedback', {
               "feedbackId": feedbackId
            });
        },
        showModal(feedback) {
            this.$bvModal.msgBoxOk(feedback, {
            title: 'Feedback',
            size: 'md',
            buttonSize: 'md',
            okVariant: 'primary',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true
            })
            .then(value => {
                this.feedback = value
            })
            .catch(err => {
                // An error occurred
            })
        }
    },
}
</script>

<style scope="">
    table{
        text-align: center !important;
    }
    th{
        padding: 20px 10px !important;
        background: transparent !important;
        text-align: center;
    }
    .f-images img{
        height: 30px;
        margin: 2.5px;
        border-radius: 5px;
        border: 2px solid transparent;
    }
    .f-images img:hover{
        border: 2px solid black;
        cursor: pointer;
    }
    #f-holder{
        box-shadow: 0px 0px 40px -25px;
        border-radius: 10px !important;
    }
    tbody{
        padding-top: 20px !important
    }
    tbody > tr{
        margin: 5px 0 !important;
        background: #fff;
        border-radius: 10px !important;
    }
    td:nth-child(1) {
        min-width: 250px !important;
    }
    td:nth-child(2) {
        min-width: 150px !important;
    }
    .f-content{
        max-width: 120px;
    }
    .wrap{
        display: webkit box;
        color: #000;
        overflow: hidden !important;
        -o-text-overflow: ellipsis !important;
        text-overflow:    ellipsis !important;
        -webkit-box-orient: vertical;
        word-wrap: break-word;
        white-space: nowrap;
    }
    .f-content:hover{
        cursor: pointer;
        font-weight: 600 !important;
    }
</style>