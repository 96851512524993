<template>
    <div class="feed-back-head pb-2 pl-1 pr-1 pt-0">
        <div class="row p-1 pt-0">
            <div class="col-12 pb-1">
                <span class="font-weight-bolder">Filters</span>
            </div>
            <div class="col-lg-3 col-12 col-md-6">
                <label>Date Range</label>
                  <flat-pickr
                    v-model="range"
                    class="form-control"
                    :config="{ mode: 'range'}"
                    />
            </div>
            <div class="col-lg-4 col-12 col-md-6">
                <label>State</label>
                <v-select  v-model="status"  :options="statusOptions"></v-select>
            </div>
            <div class="col-lg-3 col-12 col-md-6">
                <label>User Name</label>
                <input v-model="userName" type="text" class="form-control" placeholder="e.g. John Doe">
            </div>
            <div class="col-lg-2 col-12 col-md-6 pt-2 pt-lg-2" @click="filterFeedback()">
                <span class="btn btn-primary">Search</span>
            </div>
        </div>
    </div>
</template>

<script>

import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

export default {
    name: "FeeadbackHead",
    components: {
        flatPickr,
        vSelect,
    },
    data() {
      return {
            range: '',
            dateRange: '',
            userName: '',
            status: 'All',
            feedbacks: {},
            statusOptions: [
                {'label':'Pending',"value": 0},
                {'label':'Processed',"value": 1},
                {'label':'All' },

            ]
      }
    },

     watch: {
        range(newRange, oldRange) {
            newRange = newRange.split(' to ');
            if(newRange.length === 2) {
                this.dateRange = {
                    from: newRange[0],
                    to: newRange[1]
                }
            } else {
                this.dateRange = '';
            }
        }
    },

    methods: {
        filterFeedback() {
            this.$emit('update-feedbacks', {
               "dateRange": this.dateRange,
               "status": (this.status != null && this.status.value != undefined)? this.status.value : '',
               "userName": this.userName
            });
        }
    }
}
</script>

<style lang="scss" scope="">
    @import '@core/scss/vue/libs/vue-select.scss';
    @import '@core/scss/vue/libs/vue-flatpicker.scss';

    .feed-back-head > .row{
        background: white;
        box-shadow: 0px 0px 40px -25px;
        border-radius: 10px !important;
    }
</style>